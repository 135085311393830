/* eslint-disable no-unused-vars */
import accounttypesService from '../services/accounttypes.service';

const initialState = { accounttypes: null, accounttype: null };

export const accounttypes = {
    namespaced: true,
    state: initialState,
    actions: {
        async getAccountTypes({ commit }, options) {
            const accounttypes = await accounttypesService.getAccountTypes(options);
            commit('getAccountTypesSuccess', accounttypes);
        },

        async addAccountType({ commit }, newaccounttype) {
            await accounttypesService.addAccountType(newaccounttype);
        },

        async deleteAccountType({ commit }, accounttypeId) {
            await accounttypesService.deleteAccountType(accounttypeId);
        },

        async getAccountType({ commit }, accounttypeId) {
            const accounttype = await accounttypesService.getAccountType(accounttypeId);
            commit('getAccountTypeSuccess', accounttype);
        },

        async editAccountType({ commit }, modifiedaccounttype) {
            await accounttypesService.editAccountType(modifiedaccounttype); 
        },


    },
    mutations: {
        getAccountTypesSuccess(state, accounttypes) {
            state.accounttypes = accounttypes;
        },
        getAccountTypeSuccess(state, accounttype) {
            state.accounttype = accounttype;
        },
    },
    getters: {
        getAccountTypesData(state) {
            return state.accounttypes?.data;
        },
        getAccountTypesMeta(state) {
            return state.accounttypes?.meta;
        },
        getAccountType(state){
            return state.accounttype;
        }
    }
}

