/* eslint-disable no-unused-vars */
import reportingtypesService from '../services/reportingtypes.service';

const initialState = { reportingtypes: null, reportingtype: null };

export const reportingtypes = {
    namespaced: true,
    state: initialState,
    actions: {
        async getReportingTypes({ commit }, options) {
            const reportingtypes = await reportingtypesService.getReportingTypes(options);
            commit('getReportingTypesSuccess', reportingtypes);
        },

        async addReportingType({ commit }, newreportingtype) {
            await reportingtypesService.addReportingType(newreportingtype);
        },

        async deleteReportingType({ commit }, reportingtypeId) {
            await reportingtypesService.deleteReportingType(reportingtypeId);
        },

        async getReportingType({ commit }, reportingtypeId) {
            const reportingtype = await reportingtypesService.getReportingType(reportingtypeId);
            commit('getReportingTypeSuccess', reportingtype);
        },

        async editReportingType({ commit }, modifiedreportingtype) {
            await reportingtypesService.editReportingType(modifiedreportingtype); 
        },


    },
    mutations: {
        getReportingTypesSuccess(state, reportingtypes) {
            state.reportingtypes = reportingtypes;
        },
        getReportingTypeSuccess(state, reportingtype) {
            state.reportingtype = reportingtype;
        },
    },
    getters: {
        getReportingTypesData(state) {
            return state.reportingtypes?.data;
        },
        getReportingTypesMeta(state) {
            return state.reportingtypes?.meta;
        },
        getReportingType(state){
            return state.reportingtype;
        }
    }
}

