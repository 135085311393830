import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getAccountTypes(params) {
        const response = await axios.get(API_URL + "/accounttypes", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addAccountType(accounttype) {
        accounttype.type = "accounttypes"
        const newJson = dataFormatter.serialize({ stuff: accounttype })
        const response = await axios.post(API_URL + "/accounttypes", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteAccountType(accounttypeId) {
        await axios.delete(API_URL + "/accounttypes/" + accounttypeId, { headers: authHeader() });
    },

    async getAccountType(accounttypeId) {
        const response = await axios.get(API_URL + "/accounttypes/" + accounttypeId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editAccountType(accounttype) {
        accounttype.type = "accounttypes"
        const newJson = dataFormatter.serialize({ stuff: accounttype })
        const response = await axios.patch(API_URL + "/accounttypes/" + accounttype.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




