import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getHhSolarSystemTypes(params) {
        const response = await axios.get(API_URL + "/hhsolarsystemtypes", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addHhSolarSystemType(hhsolarsystemtype) {
        hhsolarsystemtype.type = "hhsolarsystemtypes"
        const newJson = dataFormatter.serialize({ stuff: hhsolarsystemtype })
        const response = await axios.post(API_URL + "/hhsolarsystemtypes", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteHhSolarSystemType(hhsolarsystemtypeId) {
        await axios.delete(API_URL + "/hhsolarsystemtypes/" + hhsolarsystemtypeId, { headers: authHeader() });
    },

    async getHhSolarSystemType(hhsolarsystemtypeId) {
        const response = await axios.get(API_URL + "/hhsolarsystemtypes/" + hhsolarsystemtypeId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editHhSolarSystemType(hhsolarsystemtype) {
        hhsolarsystemtype.type = "hhsolarsystemtypes"
        const newJson = dataFormatter.serialize({ stuff: hhsolarsystemtype })
        const response = await axios.patch(API_URL + "/hhsolarsystemtypes/" + hhsolarsystemtype.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




