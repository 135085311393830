<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Szerződés Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <Button type="button" label="Mentés" icon="pi pi-save" :loading="loading" @click.prevent="handleEdit" />
                                <Button
                                    severity="warn"
                                    type="button" label="Vissza" icon="pi pi-arrow-left"
                                    @click="this.$router.push({ name: 'Scontracts List' })" />
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row pb-2">
                                <div class="p-4 pt-1">
                                    <hr><h2 style="width: 100%;">1. SZERZŐDÉS TÁRGYA</h2>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">POD</label>
                                    <select id="choices-realestate" v-model.number="contract.pod" name="choices-realestate" class="form-control" @change="setRealEstate($event.target.value)">
                                        <option value="">Válassz!</option>
                                        <option v-for="utility in UtilitiesList" :key="utility.id" :value="utility.id">{{ utility.pod }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-3">
                                    <material-input id="realestate_name" v-model:value="contract.realestate_name" label="Hely neve"
                                        name="realestate_name" variant="static" disabled></material-input>
                                </div>

                                <div class="col-3">
                                    <material-input id="realestate_address" v-model:value="contract.realestate_address" label="Hely címe"
                                        name="realestate_address" variant="static" disabled></material-input>
                                </div>

                                <div class="col-3">
                                    <material-input id="contract_number" v-model:value="contract.contract_number" label="Szerződés száma"
                                        name="contract_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.contract_number"></validation-error>
                                </div>

                                <div class="col-3">
                                    <material-input id="utility_service" label="Szolgáltatás típusa"
                                        name="utility_service" variant="static" disabled></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-3">
                                    <material-input id="meter_product_number" label="Főmérő gyári száma"
                                        name="meter_product_number" variant="static" disabled></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-3">
                                    <material-input id="network_provider_name" label="Szolgáltató"
                                        name="network_provider_name" variant="static" disabled></material-input>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Felhasználó</label>
                                    <select id="choices-owners" v-model.number="contract.owners_id" name="choices-owners" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="owner in getOwnersList" :key="owner.id" :value="owner.id">{{ owner.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row bg-light">
                                <div class="p-4">
                                    <hr><h2 style="width: 100%;">2. SZOLGÁLTATÁSI IDŐSZAK</h2>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Időtartama</label>
                                    <select id="choices-durations" v-model.number="contract.lease_durations_id" name="choices-durations" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="duration in getLeaseDurationsList" :key="duration.id" :value="duration.id">{{ duration.name }}</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Kezdete</label>
                                    <VueDatePicker id="selected-start-date" v-model="currentStartDate" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Vége</label>
                                    <VueDatePicker id="selected-end-date" v-model="currentEndDate" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Auto. hosszabbítás</label>
                                    <select id="choices-auto_renewal" v-model.number="contract.auto_renewal" name="choices-auto_renewal" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option key="0" value="0">Nem</option>
                                        <option key="1" value="1">Igen</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Lemondási határidő</label>
                                    <select id="choices-noticeperiods" v-model="contract.notice_periods_id" name="choices-noticeperiods" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="period in getNoticePeriodsList" :key="period.id" :value="period.id">{{ period.name }}</option>
                                    </select>
                                </div>

                                <div class="p-4">
                                    <hr><h2 style="width: 100%;">3. FELHASZNÁLÁSI- ÉS FIZETÉSI FELTÉTELEK</h2>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Ellátás típusa</label>
                                    <select id="choices-supply_types" v-model="contract.supply_types_id" name="choices-supply_types" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="type in getSupplyTypesList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Adatszolgáltatási mód</label>
                                    <select id="choices-reporting_types" v-model="contract.reporting_types_id" name="choices-reporting_types" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="type in getReportingTypesList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Elszámolási mód</label>
                                    <select id="choices-account_types" v-model="contract.account_types_id" name="choices-account_types" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="type in getAccountTypesList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Fizetési mód</label>
                                    <select id="choices-payment_methods" v-model="contract.payment_methods_id" name="choices-payment_methods" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="type in getPaymentMethodsList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <material-input id="system_usage_fee" v-model:value="contract.system_usage_fee" label="Rendszerhasználati díj"
                                        name="system_usage_fee" variant="static"></material-input>
                                </div>

                                <div class="col-3">
                                    <material-input id="energy_fee" v-model:value="contract.energy_fee" label="Energiadíj"
                                        name="energy_fee" variant="static"></material-input>
                                </div>

                                <div class="col-3">
                                    <material-input id="contracted_qty" v-model:value="contract.contracted_qty" label="Szerződött mennyiség"
                                        name="contracted_qty" variant="static"></material-input>
                                </div>

                                <div class="col-3">
                                    <material-input id="ex_consumption" v-model:value="contract.ex_consumption" label="Alul- és felülfogyasztás"
                                        name="ex_consumption" variant="static"></material-input>
                                </div>

                                <div class="col-3">
                                    <label class="form-label mb-0 ms-0">Háztartási kiserömű</label>
                                    <select id="choices-hh_solar_system_types" v-model="contract.hh_solar_system_types_id" name="choices-hh_solar_system_types" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="type in getHhSolarSystemTypesList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                    </select>
                                </div>

                                <div class="col-3">
                                    <material-input id="solar_sys_performance" v-model:value="contract.solar_sys_performance" label="Kiserőmű teljesítmény"
                                        name="solar_sys_performance" variant="static"></material-input>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash";
import VueDatePicker from '@vuepic/vue-datepicker';
import { hu } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'EditScontract',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        VueDatePicker,
    },
    mixins: [formMixin],
    setup() {
        const currentStartDate = ref(new Date());
        const currentEndDate = ref(new Date());
        const visible = ref(false);
        const format = (date) => {
            const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;
        };
        return {
            color: '#33e8f5',
            currentStartDate,
            currentEndDate,
            hu,
            format,
            visible,
        }
    },
    data() {
        return {
            contract: {} 
        }
    },
    computed: {
        getOwnersList() {
            return this.$store.getters["owners/getOwnersData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getLeaseDurationsList() {
            return this.$store.getters["leasedurations/getLeasedurationsData"];
        },
        getNoticePeriodsList() {
            return this.$store.getters["noticeperiods/getNoticeperiodsData"];
        },
        UtilitiesList() {
            return this.$store.getters["unetworks/getUnetworksData"];
        },
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getMetersList() {
            return this.$store.getters["meters/getMetersData"];
        },
        getSupplyTypesList() {
            return this.$store.getters["supplytypes/getSupplyTypesData"];
        },
        getReportingTypesList() {
            return this.$store.getters["reportingtypes/getReportingTypesData"];
        },
        getAccountTypesList() {
            return this.$store.getters["accounttypes/getAccountTypesData"];
        },
        getPaymentMethodsList() {
            return this.$store.getters["paymentmethods/getPaymentMethodsData"];
        },
        getHhSolarSystemTypesList() {
            return this.$store.getters["hhsolarsystemtypes/getHhSolarSystemTypesData"];
        },
    },
    async mounted() {
        await this.$store.dispatch("owners/getOwners");
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("leasedurations/getLeasedurations");
        await this.$store.dispatch("noticeperiods/getNoticeperiods");
        await this.$store.dispatch("unetworks/getUnetworks");
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("meters/getMeters");
        await this.$store.dispatch("supplytypes/getSupplyTypes");
        await this.$store.dispatch("reportingtypes/getReportingTypes");
        await this.$store.dispatch("accounttypes/getAccountTypes");
        await this.$store.dispatch("paymentmethods/getPaymentMethods");
        await this.$store.dispatch("hhsolarsystemtypes/getHhSolarSystemTypes");

        try {
            let contract = {};
            await this.$store.dispatch("scontracts/getScontract", this.$route.params.id);
            contract = _.omit(this.$store.getters['scontracts/getScontract'], 'links');
            this.contract = contract[0];
            console.log(this.contract)
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }

        if (document.getElementById("choices-realestate")) {
            const realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            this.realestate.setChoiceByValue(String(this.contract.pod));
        }

        if (document.getElementById("choices-owners")) {
            const owners = document.getElementById("choices-owners");
            this.owners = new Choices(owners);
            this.owners.setChoiceByValue(String(this.contract.owners_id));
        }

        if (document.getElementById("choices-durations")) {
            const durations = document.getElementById("choices-durations");
            this.durations = new Choices(durations);
            this.durations.setChoiceByValue(String(this.contract.lease_durations_id));
        }

        if (document.getElementById("choices-noticeperiods")) {
            const noticeperiods = document.getElementById("choices-noticeperiods");
            this.noticeperiods = new Choices(noticeperiods);
            this.noticeperiods.setChoiceByValue(String(this.contract.notice_periods_id));
        }

        if (document.getElementById("choices-auto_renewal")) {
            const auto_renewal = document.getElementById("choices-auto_renewal");
            this.auto_renewal = new Choices(auto_renewal);
            this.auto_renewal.setChoiceByValue(String(this.contract.auto_renewal));
        }

        if (document.getElementById("choices-supply_types")) {
            const supply_types = document.getElementById("choices-supply_types");
            this.supply_types = new Choices(supply_types);
            this.supply_types.setChoiceByValue(String(this.contract.supply_types_id));
        }

        if (document.getElementById("choices-reporting_types")) {
            const reporting_types = document.getElementById("choices-reporting_types");
            this.reporting_types = new Choices(reporting_types);
            this.reporting_types.setChoiceByValue(String(this.contract.reporting_types_id));
        }

        if (document.getElementById("choices-account_types")) {
            const account_types = document.getElementById("choices-account_types");
            this.account_types = new Choices(account_types);
            this.account_types.setChoiceByValue(String(this.contract.account_types_id));
        }

        if (document.getElementById("choices-payment_methods")) {
            const payment_methods = document.getElementById("choices-payment_methods");
            this.payment_methods = new Choices(payment_methods);
            this.payment_methods.setChoiceByValue(String(this.contract.payment_methods_id));
        }

        if (document.getElementById("choices-hh_solar_system_types")) {
            const hh_solar_system_types = document.getElementById("choices-hh_solar_system_types");
            this.hh_solar_system_types = new Choices(hh_solar_system_types);
            this.hh_solar_system_types.setChoiceByValue(String(this.contract.hh_solar_system_types_id));
        }

        this.currentStartDate = new Date(this.contract.start_date);
        this.currentEndDate = new Date(this.contract?.fixed_term_end_date);
        this.setRealEstate(this.contract.pod);
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                this.contract.start_date = this.currentStartDate;
                this.contract.fixed_term_end_date = this.currentEndDate;
                await this.$store.dispatch("scontracts/editScontract", this.contract);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Szerződés sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "Scontracts List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setRealEstate(id) {
            const selectedUtility = this.UtilitiesList.filter(value => {
                return value.id == id
            });
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == selectedUtility[0]?.realestate_id
            });
            this.currentUtility = this.getutilityList.filter(value => {
                return value.id == selectedUtility[0]?.utility_services_id
            });
            this.currentMeter = this.getMetersList.filter(value => {
                return value.pod == id
            });
            document.getElementById('realestate_name').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.name;
            document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
            document.getElementById('utility_service').value = this.currentUtility[0] === undefined ? '' : this.currentUtility[0]?.name;
            document.getElementById('meter_product_number').value = this.currentMeter[0] === undefined ? '' : this.currentMeter[0]?.product_number;
            document.getElementById('network_provider_name').value = selectedUtility[0] === undefined ? '' : selectedUtility[0]?.network_provider_name;
        },
    }
}
</script>