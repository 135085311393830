/* eslint-disable no-unused-vars */
import scontractsService from '../services/scontracts.service';

const initialState = { scontracts: null, scontract: null };

export const scontracts = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getScontracts({ commit }, options) {
            const scontracts = await scontractsService.getScontracts(options);
            commit('getScontractsSuccess', scontracts);
        },

        async addScontract({ commit }, newscontract) {
            await scontractsService.addScontract(newscontract);
        }, 

        async deleteScontract({ commit }, scontractId) {
            await scontractsService.deleteScontract(scontractId);
        },

        async getScontract({ commit }, scontractId) {
            const scontract = await scontractsService.getScontract(scontractId);
            commit('getScontractSuccess', scontract);
        },

        async editScontract({ commit }, modifiedscontract) {
            await scontractsService.editScontract(modifiedscontract); 
        },


    },
    mutations: {
        getScontractsSuccess(state, scontracts) {
            state.scontracts = scontracts;
        },
        getScontractSuccess(state, scontract) {
            state.scontract = scontract;
        },
    },
    getters: {
        getScontractsData(state) {
            return state.scontracts?.data;
        },
        getScontractsMeta(state) {
            return state.scontracts?.meta;
        },
        getScontract(state){
            return state.scontract;
        }
    }
}

