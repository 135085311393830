<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Szolgáltatási szerződés Lista</h5>
              </div>
              <div class="col-6 text-end">
                <material-button
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'Add Scontract' })"
                >
                  <i class="fas fa-tag me-2"></i>
                  Szerződés Hozzáadása
                </material-button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <!-- Card body -->
          <div class="px-0 pb-0 card-body">
            
            <div class="table-responsive p-4">
            <!-- Table -->
            <DataTable id="dataTable" :data="data" :columns="columns" ref="table" class="table table-flush dataTable-table" width="100%">
              <template #action="props">
                <td
                    class = "dt-control"
                    :text="`Col 1: ${props.cellData}`"
                    @click="rowClick"
                ></td>
              </template>
            </DataTable>
          </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import $ from 'jquery';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

DataTable.use(DataTablesCore);

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

var sortDirection = "created_at";

const getScontractsList = _.debounce(async function (params) {
  await store.dispatch("scontracts/getScontracts");
}, 300);

export default {
  name: "scontractsList",
  components: {
    MaterialButton,
    MaterialInput,
    BasePagination,
    DataTable
  },
  mixins: [eventTable],
  setup() {
    const data = ref([]);
    const table = ref();
      const columns = [
          { title: 'Szerződés szám' },
          { title: 'POD' },
          { title: 'Ingatlan' },
          { title: 'Szolgáltató' },
          { title: 'Akció' },
        ];
        return {
            columns,
            data,
            table,
        }
    },
  data() {
    return {
      search: "",
      datascontracts: [],
      pagination: {},
      dataTable: null,
      keys: null,
    };
  },
  computed: {
    scontractsList() {
      return this.$store.getters["scontracts/getScontractsData"];
    },
    metaPage() {
      return this.$store.getters["scontracts/getScontractsMeta"];
    },
  },
  watch: {
    scontractsList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async mounted() {
      await getScontractsList({
        sort: sortDirection,
        query: "",
      });
  },
  beforeUnmount() {
    sortDirection = "created_at";
  },
  methods: {
    rowClick(event) {
        const table = $("#dataTable").DataTable();
        let tr = event.target.closest('tr');
        let row = table.row(tr);
    
        if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
        }
        else {
            // Open this row
            row.child(this.format(row.data())).show();
        }
    },
    format(d) {
      // `d` is the original data object for the row
      let areaHtml = "";
      areaHtml += '<table>'
      areaHtml += '<thead>'
      areaHtml += '<tr>'
      areaHtml += '<th>Szám</th>'
      areaHtml += '<th>Épület száma</th>'
      areaHtml += '<th>Helyrajzi szám</th>'
      areaHtml += '<th>Méret</th>'
      areaHtml += '<tr>'
      areaHtml += '</thead>'

      d[0].forEach(area => {
        areaHtml += '<tr>'
        areaHtml += `<td class="text-center">${area.area_detail.area_nr}</td>`;
        areaHtml += `<td class="text-center">${area.area_detail.building_nr}</td>`;
        areaHtml += `<td class="text-center">${area.area_detail.lot_number}</td>`;
        areaHtml += `<td class="text-center">${area.area_detail.gross_floor_space}</td>`;
        areaHtml += '<tr>'
      });

      areaHtml += '</table>'

      return (
          `<dl>
          <dt>Szolgáltató információ:</dt>
          <dd>
          <table>
            <tr>
              <td>${d[1]}</td>
            </tr>
          </table>
          </dd>
          <dt>Helységek:</dt>
          <dd>${areaHtml}</dd>
          </dl>`
      );
    },

    async getDataFromPage(page) {
      await getScontractsList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactiveTable() {
      this.datascontracts = [];
      if (this.scontractsList.length > 0) {
        this.scontractsList.forEach((scontract) => {
          this.datascontracts.push([
          scontract.contract_number,
          scontract.unetwork.pod,
          scontract.unetwork.get_real_estate.name,
          scontract.unetwork.network_provider_name,
          
            `<div class="text-end">${
              this.actionEditButton(scontract.id, "Szerződés módosítása") +
              this.actionDeleteButton(scontract.id, "Szerződés törlése")
            }</div>`,
          ]);
        });

        this.data = this.datascontracts;
        this.removeEvent();
        await this.$nextTick();
        this.eventToCall({
          myUserId: null,
          redirectPath: "Edit Scontract",
          deletePath: "scontracts/deleteScontract",
          getPath: "scontracts/getScontracts",
          textDelete: "Szerződés sikeresen törölve!",
          textDefaultData: "scontracts",
        });
      }
    },
  },
};
</script>
