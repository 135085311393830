/* eslint-disable no-unused-vars */
import hhsolarsystemtypesService from '../services/hhsolarsystemtypes.service';

const initialState = { hhsolarsystemtypes: null, hhsolarsystemtype: null };

export const hhsolarsystemtypes = {
    namespaced: true,
    state: initialState,
    actions: {
        async getHhSolarSystemTypes({ commit }, options) {
            const hhsolarsystemtypes = await hhsolarsystemtypesService.getHhSolarSystemTypes(options);
            commit('getHhSolarSystemTypesSuccess', hhsolarsystemtypes);
        },

        async addHhSolarSystemTypes({ commit }, newhhsolarsystemtype) {
            await hhsolarsystemtypesService.addHhSolarSystemTypes(newhhsolarsystemtype);
        },

        async deleteHhSolarSystemType({ commit }, hhsolarsystemtypeId) {
            await hhsolarsystemtypesService.deleteHhSolarSystemType(hhsolarsystemtypeId);
        },

        async getHhSolarSystemType({ commit }, hhsolarsystemtypeId) {
            const hhsolarsystemtype = await hhsolarsystemtypesService.getHhSolarSystemType(hhsolarsystemtypeId);
            commit('getHhSolarSystemtypeSuccess', hhsolarsystemtype);
        },

        async editHhSolarSystemType({ commit }, modifiedhhsolarsystemtype) {
            await hhsolarsystemtypesService.editHhSolarSystemType(modifiedhhsolarsystemtype); 
        },


    },
    mutations: {
        getHhSolarSystemTypesSuccess(state, hhsolarsystemtypes) {
            state.hhsolarsystemtypes = hhsolarsystemtypes;
        },
        getHhSolarSystemtypeSuccess(state, hhsolarsystemtype) {
            state.hhsolarsystemtype = hhsolarsystemtype;
        },
    },
    getters: {
        getHhSolarSystemTypesData(state) {
            return state.hhsolarsystemtypes?.data;
        },
        getHhSolarSystemTypesMeta(state) {
            return state.hhsolarsystemtypes?.meta;
        },
        getHhSolarSystemType(state){
            return state.hhsolarsystemtype;
        }
    }
}

