import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getScontracts(params) {
        const response = await axios.get(API_URL + "/getscontracts", { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page };
    },

    async addScontract(scontract) {
        scontract.type = "scontracts"
        const newJson = dataFormatter.serialize({ stuff: scontract })
        const response = await axios.post(API_URL + "/addscontract", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteScontract(scontractId) {
        await axios.delete(API_URL + "/scontracts/" + scontractId, { headers: authHeader() });
    },

    async getScontract(scontractId) {
        const response = await axios.get(API_URL + "/getscontract/" + scontractId, { headers: authHeader() });
        return response.data;
    },

    async editScontract(scontract) {
        scontract.type = "scontracts"
        const newJson = dataFormatter.serialize({ stuff: scontract })
        const response = await axios.patch(API_URL + "/editscontract/" + scontract.id, newJson, { headers: authHeader() });
        return response.data;
    },
}




