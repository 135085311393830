import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getReportingTypes(params) {
        const response = await axios.get(API_URL + "/reportingtypes", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addReportingType(reportingtype) {
        reportingtype.type = "reportingtypes"
        const newJson = dataFormatter.serialize({ stuff: reportingtype })
        const response = await axios.post(API_URL + "/reportingtypes", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteReportingType(reportingtypeId) {
        await axios.delete(API_URL + "/reportingtypes/" + reportingtypeId, { headers: authHeader() });
    },

    async getReportingType(reportingtypeId) {
        const response = await axios.get(API_URL + "/reportingtypes/" + reportingtypeId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editReportingType(reportingtype) {
        reportingtype.type = "reportingtypes"
        const newJson = dataFormatter.serialize({ stuff: reportingtype })
        const response = await axios.patch(API_URL + "/reportingtypes/" + reportingtype.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




