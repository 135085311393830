/* eslint-disable no-unused-vars */
import incominginvoicesService from '../services/incominginvoices.service';

const initialState = { incominginvoices: null, incominginvoice: null };

export const incominginvoices = {
    namespaced: true,
    state: initialState,
    actions: {
        async getIncomingInvoices({ commit }, options) {
            const incominginvoices = await incominginvoicesService.getIncomingInvoices(options);
            commit('getIncomingInvoicesSuccess', incominginvoices);
        },

        async addIncomingInvoice({ commit }, newIncomingInvoice) {
            await incominginvoicesService.addIncomingInvoice(newIncomingInvoice);
        },

        async deleteIncomingInvoice({ commit }, incominginvoiceId) {
            await incominginvoicesService.deleteIncomingInvoice(incominginvoiceId);
        },

        async getIncomingInvoice({ commit }, incominginvoiceId) {
            const incominginvoice = await incominginvoicesService.getIncomingInvoice(incominginvoiceId);
            commit('getIncomingInvoiceSuccess', incominginvoice);
        },

        async editIncomingInvoice({ commit }, modifiedIncomingInvoice) {
            await incominginvoicesService.editIncomingInvoice(modifiedIncomingInvoice); 
        },

        async getPodInformation({ commit }, pod) {
            const podinformation = await incominginvoicesService.getPodInformation(pod);
            commit('getPodInformationSuccess', podinformation);
        },


    },
    mutations: {
        getIncomingInvoicesSuccess(state, incominginvoices) {
            state.incominginvoices = incominginvoices;
        },
        getIncomingInvoiceSuccess(state, incominginvoice) {
            state.incominginvoice = incominginvoice;
        },
        getPodInformationSuccess(state, podinformation) {
            state.podinformation = podinformation;
        },
    },
    getters: {
        getIncomingInvoicesData(state) {
            return state.incominginvoices?.data;
        },
        getIncomingInvoicesMeta(state) {
            return state.incominginvoices?.meta;
        },
        getIncomingInvoice(state){
            return state.incominginvoice;
        },
        getPodInformationData(state) {
            return state.podinformation;
        },
    }
}

