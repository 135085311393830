import axios from "axios";
import authHeader from "./auth-header";
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
  async getConsumption(params) {
    const response = await axios.post(API_URL + "/consumption", {
      headers: authHeader(),
      params: params,
    });
    return { data: response.data, meta: response.data.meta?.page };
  },

  async addConsumption(consumption) {
    consumption.type = "consumptions";
    const newJson = dataFormatter.serialize({ stuff: consumption });
    const response = await axios.post(API_URL + "/addconsumption", newJson, {
      headers: authHeader(),
    });
    return dataFormatter.deserialize(response.data);
  },

  async getStatus(params) {
    const response = await axios.post(API_URL + "/consumptionstatus", {
      headers: authHeader(),
      params: params,
    });
    return { data: response.data, meta: response.data.meta?.page };
  },

  async getEvents(params) {
    const response = await axios.post(API_URL + "/consumptionsevents", {
      headers: authHeader(),
      params: params,
    });
    return { data: response.data, meta: response.data.meta?.page };
  },

  async getConsumptionCalculation(params) {
    const response = await axios.post(API_URL + "/calculation", {
      headers: authHeader(),
      params: params,
    });
    return { data: response.data, meta: response.data.meta?.page };
  },
};
