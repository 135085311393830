<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Bejövő Számla Módosítás</h5>
                            </div>
                            <div class="col-6 text-end">
                                <Button
                                    severity="warn"
                                    type="button" label="Vissza" icon="pi pi-arrow-left"
                                    @click="this.$router.push({ name: 'InInvoice List' })" />
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row ">

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Mérési pont azonosító</p>
                                </div>
                                <div class="col-5">
                                    <material-input id="choices-utility_networks_pod" v-model:value="invoice.pod" variant="static" disabled>
                                    </material-input>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Szolgáltatás típusa</p>
                                </div>
                                <div class="col-3">
                                    <select id="choices-utility_services_id" v-model.number="invoice.utility_services_id" class="form-control" disabled>
                                        <option v-for="sutility in getutilityList" :key="sutility.id" :value="sutility.id">{{ sutility.name }}</option>
                                    </select>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Elszámolási időszak</p>
                                </div>
                                <div class="col-5">
                                    <v-date-picker v-model="range" is-range>
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="flex justify-center items-center">
                                            <input
                                                :value="inputValue.start"
                                                v-on="inputEvents.start"
                                                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                            />
                                            <svg
                                                class="w-4 h-4 mx-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                            <input
                                                :value="inputValue.end"
                                                v-on="inputEvents.end"
                                                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                            />
                                            </div>
                                        </template>
                                    </v-date-picker>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Számla sorszáma</p>
                                </div>
                                <div class="col-3">
                                    <material-input id="invoice_number" v-model:value="invoice.invoice_number" 
                                        name="invoice_number" variant="static" disabled></material-input>
                                    <validation-error :errors="apiValidationErrors.invoice_number"></validation-error>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Kezdő és záró mérőállás</p>
                                </div>
                                <div class="col-5">
                                    <div class="flex justify-center items-center">
                                            <input
                                                id="meter_value_start"
                                                placeholder="Kwh"
                                                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                                @change="setCurrentUsage"
                                                v-model="invoice.meter_value_start"
                                            />
                                            <svg
                                                class="w-4 h-4 mx-2"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                            <input
                                                id="meter_value_end"
                                                placeholder="Kwh"
                                                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                                                @change="setCurrentUsage"
                                                v-model="invoice.meter_value_end"
                                            />
                                    </div>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Számla kelte</p>
                                </div>
                                <div class="col-3">
                                    <v-date-picker v-model="invoice_date">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input
                                            class="bg-white border px-2 py-1 rounded"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            />
                                        </template>
                                    </v-date-picker>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Hónap</p>
                                </div>
                                <div class="col-3">
                                    <VueDatePicker v-model="calendarMonth" :teleport="true" locale="hu" month-picker />
                                </div>
                            </div>
                                <div class="row border border-secondary rounded my-5">
                                        <div class="col-2" 
                                            :style="{
                                                backgroundImage:
                                                'url(' + require('@/assets/img/providers/e-on.png') + ')',
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }">
                                        </div>
                                        <div class="col-7">
                                            <div class="col-12">
                                                <p class="form-label text-black pt-2 text-bold">{{invoice.network_provider_name}}</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <p class="form-label text-black pt-2 text-bold">Szerződés száma</p>
                                                </div>
                                                <div class="col-6">
                                                    <p class="form-label text-black pt-2">{{invoice.contract_number}}</p>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <p class="form-label text-black pt-2 text-bold">Bankszámlaszám</p>
                                                </div>
                                                <div class="col-6">
                                                    <p class="form-label text-black pt-2">{{invoice.owner_account_number}}</p>
                                                </div>
                                            </div>
                                           
                                            <div class="row">
                                                <div class="col-6">
                                                    <p class="form-label text-black pt-2 text-bold">Kapcsolattartó</p>
                                                </div>
                                                <div class="col-6">
                                                    <p class="form-label text-black pt-2">{{invoice.owner_contact}}</p>
                                                </div>
                                            </div>     
                                        </div>
                                        <div class="col-3" style="background-color: #fbe3d5;">
                                            <div class="row text-center">
                                                <div class="col-12">
                                                    <p class="form-label text-black pt-2 text-bold">Számla összeg</p>
                                                </div>
                                                <div class="col-12">
                                                    <h3 class="text-black pt-2 text-bold">{{altogether}}</h3>
                                                </div>
                                                <div class="col-12">
                                                    <p class="form-label text-black pt-2 text-bold">HUF</p>
                                                </div>
                                            </div>
                                        </div>
                                </div>  
                                <div class="row ">
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Főmérő gyári száma</p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">{{invoice.product_number}}</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Fogyasztó neve</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">{{invoice.owner_name}}</p>
                                    </div>


                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Aktuális fogyasztás </p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">{{currentUsage}} kWh</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Partnerszám</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">1000000352</p>
                                    </div>


                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Aktuális visszatáplálás</p>
                                    </div>
                                    <div class="col-5">
                                        <material-input id="invoice_number" v-model:value="invoice.actual_recuperation" 
                                        name="invoice_number" placeholder="Kwh" variant="static"></material-input>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Fogyasztási hely neve</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">{{invoice.realestate_name}}</p>
                                    </div>


                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Szerződés lejárati idő </p>
                                    </div>
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2">{{invoice.fixed_term_end_date}}</p>
                                    </div>

                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Fogyasztási hely címe</p>
                                    </div>
                                    <div class="col-3">
                                        <p class="form-label text-black pt-2">{{invoice.realestate_zip + ' ' + invoice.realestate_city + ', ' + invoice.realestate_address}}</p>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 text-bold">Tétel megnevezése</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 text-bold">ÁFA%</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Nettó érték (HUF)</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Áfa összege(HUF)</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 text-bold">Bruttó érték (HUF)</p>
                                    </div>

                                    <hr>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Energiadíj összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="energy_price_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="energy_price_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="energy_price" v-model:value="invoice.energy_price" 
                                            name="energy_price" variant="static" 
                                            v-on:change="printOutAFAValue('energy_price_AFA_out','energy_price'),printOutNetValue('energy_price_Net_out','energy_price'),sumWithAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.energy_price"></validation-error>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Jövedéki adó összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="excise_tax_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="excise_tax_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="excise_tax" v-model:value="invoice.excise_tax" 
                                            name="excise_tax" variant="static" 
                                            v-on:change="printOutAFAValue('excise_tax_AFA_out','excise_tax'),printOutNetValue('excise_tax_Net_out','excise_tax'),sumWithAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.excise_tax"></validation-error>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Pénzeszközök összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">AHK</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="cash_equivalents_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2">- </p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="cash_equivalents" v-model:value="invoice.cash_equivalents" 
                                            name="cash_equivalents" variant="static" 
                                            v-on:change="printOut('cash_equivalents_out','cash_equivalents'),sumWithoutAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.cash_equivalents"></validation-error>
                                    </div>
                                    
                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">KÁT és Prémium pénzeszközök összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">AHK</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="kat_and_premium_equivalents"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2">- </p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="KAT_and_premium_equivalents" v-model:value="invoice.kat_and_premium_equivalents" 
                                            name="KAT_and_premium_equivalents" variant="static" 
                                            v-on:change="printOut('KAT_and_premium_equivalents_out','kat_and_premium_equivalents'),sumWithoutAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.kat_and_premium_equivalents"></validation-error>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Rendszerhasználati díjak összesen </p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="grid_access_fees_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="grid_access_fees_AFA_out">  </p>
                                    </div>
                                    <div class="col-2">
                                        <material-input id="grid_access_fees" v-model:value="invoice.grid_access_fees" 
                                            name="grid_access_fees" variant="static" 
                                            v-on:change="printOutAFAValue('grid_access_fees_AFA_out','grid_access_fees'),printOutNetValue('grid_access_fees_Net_out','grid_access_fees'),sumWithAFA(),sumTotal()"></material-input>
                                        <validation-error :errors="apiValidationErrors.grid_access_fees"></validation-error>
                                    </div>

                                    <hr>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Összesítés (ÁFA szerint)</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">27</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_with_afa_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="sum_with_afa_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_with_afa"></p>
                                    </div>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Összesítés (ÁFA szerint)</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 ">AHK</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_without_afa"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2">-</p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="sum_without_afa2"></p>
                                    </div>
                                    
                                    <hr>

                                    <div class="col-5">
                                        <p class="form-label text-black pt-2 ">Számla összesen</p>
                                    </div>
                                    <div class="col-1">
                                        <p class="form-label text-black pt-2 "></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2 " id="gross_amount_Net_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="gross_amount_AFA_out"></p>
                                    </div>
                                    <div class="col-2">
                                        <p class="form-label text-black pt-2" id="gross_amount"></p>
                                    </div>
                                    <Button type="button" label="Számla hozzáadás" icon="pi pi-save" :loading="loading" @click.prevent="handleAdd" />
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'EditInInvoice',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        const calendarMonth = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        return {
            color: '#33e8f5',
            month,
            calendarMonth
        }
    },
    data() {
        return {
            range: {
                start: new Date(),
                end: new Date(),
            },
            invoice_date: new Date(),
            invoice: {},
            //date: null,
            altogether: 0,
            currentUsage: 0,
        }
    },
    computed: {
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        UtilitiesList() {
            return this.$store.getters["unetworks/getUnetworksData"];
        },
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("unetworks/getUnetworks");

        try {
            await this.$store.dispatch("incominginvoices/getIncomingInvoice", this.$route.params.id);
            this.invoice = _.omit(this.$store.getters['incominginvoices/getIncomingInvoice'], 'links');
            this.month.month = this.invoice.month;
            this.month.year = this.invoice.year;
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }

        if (document.getElementById("choices-utility_services_id")) {
            var mutility = document.getElementById("choices-utility_services_id");
            new Choices(mutility);
        }

        this.getPodInformation(this.invoice.pod);
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                this.invoice.invoice_date = this.invoice_date;
                this.invoice.accounting_period_start = this.range.start;
                this.invoice.accounting_period_end = this.range.end;
                this.invoice.actual_usage = this.currentUsage;
                this.invoice.gross_amount = this.altogether;
                this.invoice.calendarMonth = this.calendarMonth;
                await this.$store.dispatch("incominginvoices/addIncomingInvoice", this.invoice);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Számla sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "InInvoice List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async getPodInformation(pod){
            await this.$store.dispatch("incominginvoices/getPodInformation", pod);
            const podInformation = _.omit(this.$store.getters['incominginvoices/getPodInformationData']);
            
            let invoice = {};
            let range = {};
            let calendarMonth = {};
            invoice.pod = pod;
            invoice.invoice_number = this.invoice.invoice_number;
            invoice.contract_number = this.invoice.contract_number;
            invoice.invoice_date = this.invoice.invoice_date;
            invoice.meter_value_start = this.invoice.meter_value_start;
            invoice.meter_value_end = this.invoice.meter_value_end;
            invoice.actual_usage = this.invoice.actual_usage;
            invoice.actual_recuperation = this.invoice.actual_recuperation;
            invoice.energy_price = this.invoice.energy_price;
            invoice.excise_tax = this.invoice.excise_tax;
            invoice.cash_equivalents = this.invoice.cash_equivalents;
            invoice.kat_and_premium_equivalents = this.invoice.kat_and_premium_equivalents;
            invoice.grid_access_fees = this.invoice.grid_access_fees;
            invoice.gross_amount = this.invoice.gross_amount;
            invoice.r_year = this.invoice.r_year;
            invoice.r_month = this.invoice.r_month;
            invoice.product_number = podInformation[0].get_meters[0].product_number;
            invoice.network_provider_name = podInformation[0].unetwork.network_provider_name;
            invoice.utility_services_id = podInformation[0].unetwork.utility_services_id;
            invoice.realestate_name = podInformation[0].unetwork.get_real_estate.name;
            invoice.realestate_city = podInformation[0].unetwork.get_real_estate.city;
            invoice.realestate_address = podInformation[0].unetwork.get_real_estate.address;
            invoice.realestate_zip = podInformation[0].unetwork.get_real_estate.zip;
            invoice.owner_name = podInformation[0].get_owner.name;
            invoice.owner_contact = podInformation[0].get_owner.contact;
            invoice.owner_account_number = podInformation[0].get_owner.account_number;
            invoice.fixed_term_end_date = podInformation[0].fixed_term_end_date;
            range.start = new Date(this.invoice.accounting_period_start);
            range.end = new Date(this.invoice.accounting_period_end);
            calendarMonth.month = invoice.r_month - 1;
            calendarMonth.year = invoice.r_year;
            
            this.range = range;
            this.invoice_date = new Date(this.invoice.invoice_date);
            this.calendarMonth = calendarMonth;

            this.invoice = invoice;
            this.setCurrentUsage();
            this.sumWithAFA();
            this.sumWithoutAFA();
            this.sumTotal();
        },
        printOut(output,input){
            document.getElementById(output).innerHTML=document.getElementById(input).value;
        },
        printOutAFAValue(output,input){
            document.getElementById(output).innerHTML=Math.round(~~document.getElementById(input).value-~~document.getElementById(input).value/1.27);
        },
        printOutNetValue(output,input){
            document.getElementById(output).innerHTML=Math.round(~~document.getElementById(input).value/1.27);
        },
        sumWithAFA(){
            var calculate=~~document.getElementById("grid_access_fees").value+~~document.getElementById("energy_price").value+~~document.getElementById("excise_tax").value;
            var sumWithAfa=Math.round(calculate-calculate/1.27);
            document.getElementById("sum_with_afa").innerHTML=calculate;
            document.getElementById("sum_with_afa_Net_out").innerHTML=Math.round(calculate/1.27);
            document.getElementById("sum_with_afa_AFA_out").innerHTML=sumWithAfa;
            document.getElementById("gross_amount_AFA_out").innerHTML=sumWithAfa;
        },
        sumWithoutAFA(){
            var out=~~document.getElementById("kat_and_premium_equivalents").value+~~document.getElementById("cash_equivalents").value;
            document.getElementById("sum_without_afa").innerHTML=out;
            document.getElementById("sum_without_afa2").innerHTML=out;
        },
        sumTotal(){
            var calculate=~~document.getElementById("sum_without_afa").innerHTML+~~document.getElementById("sum_with_afa").innerHTML;
            this.altogether = calculate;
            
            document.getElementById("gross_amount").innerHTML=calculate;
            document.getElementById("gross_amount_Net_out").innerHTML=Math.round((~~document.getElementById("grid_access_fees").value+~~document.getElementById("energy_price").value+~~document.getElementById("excise_tax").value)/1.27)+~~document.getElementById("kat_and_premium_equivalents").value+~~document.getElementById("cash_equivalents").value;

        },
        setCurrentUsage(){
            this.currentUsage = ~~document.getElementById("meter_value_end").value - ~~document.getElementById("meter_value_start").value
        }
    }
}


</script>