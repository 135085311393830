/* eslint-disable no-unused-vars */
import supplytypesService from '../services/supplytypes.service';

const initialState = { supplytypes: null, supplytype: null };

export const supplytypes = {
    namespaced: true,
    state: initialState,
    actions: {
        async getSupplyTypes({ commit }, options) {
            const supplytypes = await supplytypesService.getSupplyTypes(options);
            commit('getSupplyTypesSuccess', supplytypes);
        },

        async addSupplyType({ commit }, newsupplytype) {
            await supplytypesService.addSupplyType(newsupplytype);
        },

        async deleteSupplyType({ commit }, supplytypeId) {
            await supplytypesService.deleteSupplyType(supplytypeId);
        },

        async getSupplyType({ commit }, supplytypeId) {
            const supplytype = await supplytypesService.getSupplyType(supplytypeId);
            commit('getSupplyTypeSuccess', supplytype);
        },

        async editSupplyType({ commit }, modifiedsupplytype) {
            await supplytypesService.editSupplyType(modifiedsupplytype); 
        },


    },
    mutations: {
        getSupplyTypesSuccess(state, supplytypes) {
            state.supplytypes = supplytypes;
        },
        getSupplyTypeSuccess(state, supplytype) {
            state.supplytype = supplytype;
        },
    },
    getters: {
        getSupplyTypesData(state) {
            return state.supplytypes?.data;
        },
        getSupplyTypesMeta(state) {
            return state.supplytypes?.meta;
        },
        getSupplyType(state){
            return state.supplytype;
        }
    }
}

