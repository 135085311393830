<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Szerződés hozzáadása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Leases List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Bérbeadó</label>
                                    <select id="choices-owners" v-model="lease.owners_id" name="choices-owners" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="owner in getOwnersList" :key="owner.id" :value="owner.id">{{ owner.name }}</option>
                                    </select>
                                </div>
                                
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Bérlő</label>
                                    <select id="choices-contacts" v-model="lease.contacts_id" name="choices-contacts" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="contact in getContactsList" :key="contact.id" :value="contact.id">{{ contact.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0"> Ingatlan megnevezése</label>
                                    <select id="choices-realestate" v-model.number="lease.realestate_id" name="choices-realestate" class="form-control" @change="setRealEstate($event.target.value)">
                                        <option value="">Válassz!</option>
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="realestate_address" v-model:value="lease.realestate_address" label="Hely címe"
                                        name="realestate_address" variant="static" disabled></material-input>
                                </div>
                            </div>

                            <br />

                            <div class="row p-4 bg-light">
                                <div class="col-4">
                                    <label>Bérelt helységek</label>
                                    <MultiSelect v-model:selectValue="lease.areas_id" :options="areas" select-all="true" select-all-label="Mindet kijelöl" cleaner="false" @change="() => setFloorSpace()" @show="() => setChoice()" />
                                    <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                                </div>

                                <div class="col-4">
                                    <material-input id="contract_based_floor_space" v-model:value="lease.contract_based_floor_space" label="Szerződés szerinti bérelt alapterület"
                                        name="contract_based_floor_space" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.contract_based_floor_space"></validation-error>
                                </div>
                                
                                <div class="col-4">
                                    <material-input id="gross_floor_space" v-model:value="lease.gross_floor_space" label="Bérelt bruttó alapterület"
                                        name="gross_floor_space" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.gross_floor_space"></validation-error>
                                </div>

                                <div class="col-4">
                                    <label class="form-label mb-0 ms-0">Bérleti szerződés időtartama</label>
                                    <select id="choices-durations" v-model="lease.lease_durations_id" name="choices-durations" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="duration in getLeaseDurationsList" :key="duration.id" :value="duration.id">{{ duration.name }}</option>
                                    </select>
                                </div>

                                <div class="col-4">
                                    <label class="form-label mb-0 ms-0">Határozott időszak vége</label>
                                    <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                </div>

                                <div class="col-4">
                                    <label class="form-label mb-0 ms-0">Bérleti időszak kezdete</label>
                                    <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                </div>

                                <div class="p-4">
                                    <hr><h2 style="width: 100%;">Bérleti díj</h2>
                                </div>
                                
                                <div class="col-5">
                                    <material-input id="rent" v-model:value="lease.rent" label="Bérleti díj"
                                        name="rent" variant="static" @input="CalculateRent"></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="total_rent" v-model:value="lease.total_rent" label="Bérleti díj összesen"
                                        name="total_rent" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Bérleti díj árindexálás</label>
                                    <select id="choices-priceindexes" v-model="lease.rent_price_indexes_id" name="choices-priceindexes" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="index in getPriceIndexesList" :key="index.id" :value="index.id">{{ index.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="rent_price_indexe_maximum" v-model:value="lease.rent_price_indexes_maximum" label="Bérleti díj árindexálás maximum"
                                        name="rent_price_indexe_maximum" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.rent_price_indexe_maximum"></validation-error>
                                </div>

                                <div class="p-4">
                                    <hr><h2 style="width: 100%;">Közös költség</h2>
                                </div>

                                <div class="col-5">
                                    <material-input id="common_cost" v-model:value="lease.common_cost" label="Közös költség"
                                        name="common_cost" variant="static" @input="CalculateCommonCost"></material-input>
                                    <validation-error :errors="apiValidationErrors.common_cost"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="total_common_cost" v-model:value="lease.total_common_cost" label="Közös költség összesen"
                                        name="total_common_cost" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.total_common_cost"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Közös költség árindexálás</label>
                                    <select id="choices-commonindexes" v-model="lease.common_cost_indexes_id" name="choices-commonindexes" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="index in getPriceIndexesList" :key="index.id" :value="index.id">{{ index.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="common_cost_maximum" v-model:value="lease.common_cost_price_indexes_maximum" label="Közös költség árindexálás maximum"
                                        name="common_cost_maximum" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="p-4">
                                    <hr><h2 style="width: 100%;">Egyéb</h2>
                                </div>
                                
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Felmondási idő</label>
                                    <select id="choices-noticeperiods" v-model="lease.notice_periods_id" name="choices-noticeperiods" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="period in getNoticePeriodsList" :key="period.id" :value="period.id">{{ period.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="caution" v-model:value="lease.caution" label="Kaució"
                                        name="caution" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>
                                
                            </div>

                            <div class="row mt-2 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import { CButton, CCollapse, CCard, CCardBody } from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import MultiSelect from "@/components/MultiSelect.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import { hu } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'AddLease',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
        MultiSelect,
        ValidationError,
        CButton,
        CCollapse,
        CCard,
        CCardBody
    },
    mixins: [formMixin],
    setup() {
        const currentDate = ref(new Date());
        const currentSubDate = ref(new Date());
        const areas = ref([]);
        const visible = ref(false);
        const format = (date) => {
            const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;
        };
        return {
            color: '#33e8f5',
            currentDate,
            currentSubDate,
            hu,
            format,
            areas,
            visible,
        }
    },
    data() {
        return {
            lease: {},
            sublease: [],
            date: null,
            realestate: null,
            ownersdata: [
                {
                    ownerName: "",
                    ownership: "",
                },
            ],
        }
    },
    computed: {
        getOwnersList() {
            return this.$store.getters["owners/getOwnersData"];
        },
        getContactsList() {
            return this.$store.getters["contacts/getContactsData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        AreaList() {
            return this.$store.getters["areas/getAreasData"];
        },
        getLeaseDurationsList() {
            return this.$store.getters["leasedurations/getLeasedurationsData"];
        },
        getPriceIndexesList() {
            return this.$store.getters["priceindexes/getPriceindexesData"];
        },
        getNoticePeriodsList() {
            return this.$store.getters["noticeperiods/getNoticeperiodsData"];
        },
        watch: {
            AreaList: function () {
                this.setChoice();
            },
        },
        ownerName(index) {
            if (typeof(index) != 'undefined') {
                return this.apiValidationErrors['owner' + (index + 1)];
            }
            return null;
        },
    },
    async mounted() {
        await this.$store.dispatch("owners/getOwners");
        await this.$store.dispatch("contacts/getContacts");
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("areas/getAreas");
        //await this.$store.dispatch("leasedurations/getLeasedurations");
        //await this.$store.dispatch("priceindexes/getPriceindexes");
        //await this.$store.dispatch("noticeperiods/getNoticeperiods");

        if (document.getElementById("choices-owners")) {
            const owners = document.getElementById("choices-owners");
            new Choices(owners);
        }

        if (document.getElementById("choices-contacts")) {
            const contacts = document.getElementById("choices-contacts");
            new Choices(contacts);
        }

        if (document.getElementById("choices-durations")) {
            const durations = document.getElementById("choices-durations");
            new Choices(durations);
        }

        if (document.getElementById("choices-noticeperiods")) {
            const noticeperiods = document.getElementById("choices-noticeperiods");
            new Choices(noticeperiods);
        }

        if (document.getElementById("choices-priceindexes")) {
            const priceindexes = document.getElementById("choices-priceindexes");
            new Choices(priceindexes);
        }

        if (document.getElementById("choices-commonindexes")) {
            const commonindexes = document.getElementById("choices-commonindexes");
            new Choices(commonindexes);
        }

        if (document.getElementById("choices-realestate")) {
            const realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            if (this.realestate.getValue(true) != undefined){
                this.currentRealEstate = this.getRealEstateList.filter(value => {
                    return value.id == this.realestate.getValue(true)
                });
                await this.$store.dispatch("areas/getAreas", {
                    filter: {
                        ...(this.realestate.getValue(true) ? { realestate_id: String(this.realestate.getValue(true)) } : {}),
                    },
                });
                document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
            }
        }
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                this.lease.currentDate = this.currentDate;
                //this.lease.sublease = this.sublease;
                await this.$store.dispatch("leases/addLease", this.lease);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Főmérő sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Leases List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setRealEstate(id) {
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == id
            });
            await this.$store.dispatch("areas/getAreas", {
                    filter: {
                        ...(id ? { realestate_id: id } : {}),
                    },
                });
            document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
        },
        setChoice() {
            this.areas = this.AreaList.map((item) => ({
                value: item.id, 
                label: item.area_nr,
            }));
        },
        setFloorSpace() {
            let total_floor_space = 0;
            this.lease.areas_id.forEach((selectedValue, index) => {
                let currentArea = this.AreaList.filter(value => {
                    console.log(value.id)
                    return value.id === selectedValue.value
                });
                total_floor_space += Number(currentArea[0].gross_floor_space);
            });
            document.getElementById("gross_floor_space").value = total_floor_space.toFixed(2);
            this.lease.gross_floor_space = total_floor_space.toFixed(2);
        },
        CalculateRent() {
            document.getElementById("total_rent").value = document.getElementById("rent").value * document.getElementById("contract_based_floor_space").value;
            this.lease.total_rent = document.getElementById("rent").value * document.getElementById("contract_based_floor_space").value;
        },
        CalculateCommonCost() {
            document.getElementById("total_common_cost").value = document.getElementById("common_cost").value * document.getElementById("contract_based_floor_space").value;
            this.lease.total_common_cost = document.getElementById("common_cost").value * document.getElementById("contract_based_floor_space").value;
        },
        async addMore() {
            this.ownersdata.push({
                ownerName: "",
                ownership: "",
            });
            const arrayLength = this.ownersdata.length;
            await this.$nextTick(function () {
                if (document.getElementById("choices-sub-munits" + arrayLength)) {
                    const munits = document.getElementById("choices-sub-munits" + arrayLength);
                    new Choices(munits);
                }

                if (document.getElementById("choices-sub-types" + arrayLength)) {
                    const stypes = document.getElementById("choices-sub-types" + arrayLength);
                    new Choices(stypes);
                }

                if (document.getElementById("choices-sub-position"+ arrayLength)) {
                    const position = document.getElementById("choices-sub-position"+ arrayLength);
                    new Choices(position);
                }
            });
        },
        remove(index) {
            this.ownersdata.splice(index, 1);
        },
        updateOwner: function(value, index) {
            this.owner['owner' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateOwnership: function(value, index) {
            this.owner['ownership' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateSublease: function(index, type, value) {
            if (this.sublease[index] === undefined){
                this.sublease[index] = {};
            }
            if (type == 'commissioning_date') {
                this.sublease[index][type] = this.format(value);
            } else {
                this.sublease[index][type] = value;
            }
        }
    }
}
</script>