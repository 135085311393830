<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12 ms-auto">
        <div class="mb-4 d-flex">
         
          <div class="ps-4">
            <button
              class="mt-3 mb-0 btn btn-outline-dark btn-icon-only"
              data-bs-toggle="modal"
              data-target="#new-board-modal"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-xl-9">
        <div class="card card-calendar p-4">
          <FullCalendar ref="calendar" :options='calendarOptions' />
        </div>
      </div>
      <div class="col-xl-3">
        <div class="row">
          <div class="mt-4 col-xl-12 col-md-6 mt-xl-0">
            <events-card
              :events="status"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import EventsCard from "./components/EventsCard.vue";
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    EventsCard,
  },
  data: function() {
    return {
      status: [],
      events: [],
      currentDate: null,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          interactionPlugin
        ],
        initialView: 'dayGridMonth',
        editable: true,
        selectable: true,
        weekends: false,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        events: []
      }
    }
  },
  async mounted() {
      await this.$store.dispatch("consumption/getStatus");
      await this.$store.dispatch("consumption/getEvents");
      const status = await this.$store.getters['consumption/getStatusData'];
      const events = await this.$store.getters['consumption/getEventsData'];

      status.forEach(item => {
          let name = item.name;
          this.status.push({
            icon: 'attach_money',
            color: 'dark',
            event: name,
            date: 'Státusz:',
          });
      });

      events.forEach(item => {
          let pod = item.pod;
          let registration_date = item.registration_date;
          this.events.push({
            title: pod,
            start: new Date(registration_date),
          });
      });

      this.calendarOptions.events = this.events;
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      this.$router.push({ name: 'ConsumptionsRegistration', query: { selectedDate: selectInfo.startStr } })
    },
    handleEventClick(clickInfo) {
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }
    },
    handleEvents(events) {
      let calendarApi = this.$refs.calendar.getApi();
      this.currentDate = calendarApi.getDate()
      //console.log(calendarApi.getDate().getMonth());
      this.currentEvents = events
    },
  }
}
</script>
