<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Bejövő Számlák</h5>
              </div>
              <div class="col-6 text-end">
                <material-button
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'Add InInvoice' })"
                >
                  <i class="fas fa-tag me-2"></i>
                  Számla Felvitele
                </material-button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="px-0 pb-0 card-body">
            
            <div class="table-responsive p-4">
            <!-- Table -->
            <DataTable id="dataTable" :data="data" :columns="columns" ref="table" class="table table-flush dataTable-table" width="100%">
              <template #action="props">
                <td
                    class = "dt-control"
                    :text="`Col 1: ${props.cellData}`"
                    @click="rowClick"
                ></td>
              </template>
            </DataTable>
          </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";
import $ from "jquery";

DataTable.use(DataTablesCore);

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

var sortDirection = "created_at";

const getIncomingInvoicesList = _.debounce(async function (params) {
  await store.dispatch("incominginvoices/getIncomingInvoices");
}, 300);

export default {
  name: "IncomingInvoiceList",
  components: {
    MaterialButton,
    MaterialInput,
    BasePagination,
    DataTable
  },
  mixins: [eventTable],
  setup() {
    const data = ref([]);
    const table = ref();
    const columns = [
          { title: 'Számlaszám' },
          { title: 'Szerződésszám' },
          { title: 'POD' },
          { title: 'Szolgáltatás' },
          { title: 'Dátum' },
          { title: 'Összeg' },
          { title: 'Action' },
        ];
        return {
            columns,
            data,
            table,
        }
  },
  data() {
    return {
      search: "",
      dataIncomingInvoices: [],
      pagination: {},
      dataTable: null,
      keys: null,
    };
  },
  computed: {
    incominginvoicesList() {
      return this.$store.getters["incominginvoices/getIncomingInvoicesData"];
    },
    metaPage() {
      return this.$store.getters["incominginvoices/getIncomingInvoicesMeta"];
    },
  },
  watch: {
    incominginvoicesList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async mounted() {
    await getIncomingInvoicesList({
        sort: sortDirection,
        query: "",
        //nr: getCurrentPage,
        //perpage: this.dataTable.options.perPage,
      });
      $('#dataTable .dt-empty').html('Nincs megjeleníthető adat.');
  },
  beforeUnmount() {
    sortDirection = "created_at";
  },
  methods: {
    async getDataFromPage(page) {
      await getIncomingInvoicesList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactiveTable() {
      this.dataIncomingInvoices = [];
      if (this.incominginvoicesList.length > 0) {
        this.incominginvoicesList.forEach((incominginvoice) => {
          this.dataIncomingInvoices.push([
          incominginvoice.invoice_number,
          incominginvoice.contract_number,
          incominginvoice.pod,
          incominginvoice.utility_services_id,
          incominginvoice.invoice_date,
          incominginvoice.gross_amount,
          
            `<div class="text-end">${
              this.actionEditButton(incominginvoice.id, "Számla módosítása") +
              this.actionDeleteButton(incominginvoice.id, "Számla törlése")
            }</div>`,
          ]);
        });

        this.data = this.dataIncomingInvoices;
        this.removeEvent();
        await this.$nextTick();
        this.eventToCall({
          table: this.dataTable,
          myUserId: null,
          redirectPath: "Edit InInvoice",
          deletePath: "incominginvoices/deleteIncomingInvoice",
          getPath: "incominginvoices/getIncomingInvoices",
          textDelete: "Számla sikeresen törölve!",
          textDefaultData: "incominginvoices",
        });
      }
      else {
        this.data = this.dataIncomingInvoices;
      }
    },
  },
};
</script>

<style scoped lang="css">
  @import 'datatables.net-bs5';
</style>
