/* eslint-disable no-unused-vars */
import paymentmethodsService from '../services/paymentmethods.service';

const initialState = { paymentmethods: null, paymentmethod: null };

export const paymentmethods = {
    namespaced: true,
    state: initialState,
    actions: {
        async getPaymentMethods({ commit }, options) {
            const paymentmethods = await paymentmethodsService.getPaymentMethods(options);
            commit('getPaymentMethodsSuccess', paymentmethods);
        },

        async addPaymentMethods({ commit }, newpaymentmethod) {
            await paymentmethodsService.addPaymentMethods(newpaymentmethod);
        },

        async deletePaymentMethod({ commit }, paymentmethodId) {
            await paymentmethodsService.deletePaymentMethod(paymentmethodId);
        },

        async getPaymentMethod({ commit }, paymentmethodId) {
            const paymentmethod = await paymentmethodsService.getPaymentMethod(paymentmethodId);
            commit('getPaymentMethodSuccess', paymentmethod);
        },

        async editPaymentMethod({ commit }, modifiedpaymentmethod) {
            await paymentmethodsService.editPaymentMethod(modifiedpaymentmethod); 
        },


    },
    mutations: {
        getPaymentMethodsSuccess(state, paymentmethods) {
            state.paymentmethods = paymentmethods;
        },
        getPaymentMethodSuccess(state, paymentmethod) {
            state.paymentmethod = paymentmethod;
        },
    },
    getters: {
        getPaymentMethodsData(state) {
            return state.paymentmethods?.data;
        },
        getPaymentMethodsMeta(state) {
            return state.paymentmethods?.meta;
        },
        getPaymentMethod(state){
            return state.paymentmethod;
        }
    }
}

